const ManageWidgetsAlumni = () => {

    return (
        <>
        <div className="main flex-1 bg-white">
        <div className="max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto">
       <div className="d-flex justify-content-between align-items-center mb-3 pb-3 custom-bottom-liteblue">
         <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title position-relative">
           Manage Widgets For Alumni 
         </h3>    
         
        
       </div>
  
       
       <div className="manage-widgets-wrap">
         <ul>
           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Hero"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Hero">
                     Hero Shot Cover
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-widgets-edit me-2"><span className="">Edit</span></a>
                   <a href="bulk-imports-map.html" className="btn btn-priview"><span className="">Preview</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="MessageSchool"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Message">
                     Message From School - Appeal Video
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Alumni"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Alumni">
                     Alumni in Spotlight
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Directory"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Directory">
                     Directory Card / Stats
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-widgets-edit me-2"><span className="">Edit</span></a>
                   <a href="bulk-imports-map.html" className="btn btn-priview"><span className="">Preview</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Adding"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Adding">
                     Adding Value to Alumni
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="News"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="News">
                     News and Updates
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                    
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Notable"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Notable">
                     Notable Alumni / Mentors
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Interactive"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Interactive">
                     Interactive Map Location  
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Events"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Events">
                     Events (Webinar + Pairing)
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Jobs"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Jobs">
                     Jobs and Internships
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Memories"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Memories">
                     Memories
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Guidance"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Guidance">
                     Guidance Videos
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Merchandise"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Merchandise">
                     Alumni Merchandise
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Exclusive"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Exclusive">
                     Exclusive Offers
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>

           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Contribute"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Contribute">
                     Contribute
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-widgets-edit me-2"><span className="">Edit</span></a>
                   <a href="bulk-imports-map.html" className="btn btn-priview"><span className="">Preview</span></a>
                 </div>
               </div>
             </div>
           </li>
           
           <li>
             <div className="row g-2 g-md-3">                   
               <div className="col-md">
                 <div className="form-check form-check-tick">
                   <input className="form-check-input" type="checkbox" value="" id="Contact"/>
                   <label className="form-check-label font-18 font-500 flex-1" htmlFor="Contact">
                     Contact Us
                   </label>
                 </div>
               </div>
               <div className="col-md-auto">
                 <div className="d-flex align-items-center">
                   <a href="bulk-imports-map.html" className="btn btn-configure"><span className="">CONFIGURE</span></a>
                 </div>
               </div>
             </div>
           </li>
         </ul>
       </div>
     </div>
        </div>
        </>
    );
};

export default ManageWidgetsAlumni;