import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import SignupLayout from './SignupLayout';
import { onLogIn, onMobileNumberChange, onSignUp } from './signupAction';

const StudentSignup = () => {
    const { globalState } = useContext(GlobalContext);
    const { schoolDomain, invitedBy } = useParams();
    // to get the page name
    const location = useLocation();
    const pageName = location.pathname.includes('signup') ? 'student-signup' : 'student-login';
    const navigate = useNavigate();
    const [mode, setMode] = useState(pageName === 'student-login' ? 'login' : 'signUp');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { SIGN_UP: onSignUp, LOG_IN: onLogIn, ROUTER_NAVIGATE: navigate, mobileNumber: onMobileNumberChange },
    } as any);

    const { formConfig } = useLoaderData() as State;

    pageName === 'student-login' ? mode === 'signUp' && setMode('login') : mode === 'login' && setMode('signUp');

    const handleNewUser = () => {
        setMode('signUp');
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/student/${schoolDomain}/signup`, { replace: false });
    };
    const handleExistingUser = () => {
        setMode('login');
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/student/${schoolDomain}/login`, { replace: false });
    };

    useEffect(() => {
        const loadSchoolSettings = async () => {
            let schoolId = '';
            const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
            localStorage.setItem('school_settings', JSON.stringify(response.data));
            localStorage.setItem('school-image', response.data.logo);
            sessionStorage.setItem('school-image', response.data.logo);
            schoolId = response.data.id;

            if (schoolId == null) {
                alert('School not found. Please contact your school admin.');
            }
            const domain = globalState.domain;
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { user: { schoolId: schoolId as any, invitedBy, provideUpdatesOnWhatsapp: true } },
                    domain,
                    routeInfo: { pageName, schoolDomain },
                },
            });
        };

        loadSchoolSettings();
    }, [mode]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <SignupLayout
                mode={pageName === 'student-signup' ? 'signUp' : 'login'}
                handleNewUser={handleNewUser}
                handleExistingUser={handleExistingUser}>
                {!state.flags.isDataLoading && <PageBuilder />}
            </SignupLayout>
        </SmartContext.Provider>
    );
};

export default StudentSignup;
