// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bigVerticalProgress .graphContainer .progress {
    height: 177px;
    align-items: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/library/SimpleControls/custom-control.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".bigVerticalProgress .graphContainer .progress {\n    height: 177px;\n    align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
