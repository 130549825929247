import axios from 'axios';

export const getProductsTestMenuStudent = async (school_id: any, class_code: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_COMMON_API}/test/get-test-menu-by-schoolid_campuscode`, {
                'SchoolId': school_id,
                'ClassCode': class_code
            });
        if (response.data.success) {
            console.log(response.data.data); 
            sessionStorage.setItem('product-menu-test', JSON.stringify(response.data.data));
        } else {
            console.error('Error:', response.data.message)
        }
    } catch (error) {
        console.error('Error fetching data:', error)
    }
};


