import { useEffect, useRef } from "react";
import { initNextBtnClickOnEnterEvent } from "../../../library/Core/SmartFunctions";

const LoginCarouselControl = () => {
    const data = [
        {
            id: 10,
            imageUrl: 'https://s3.ap-south-1.amazonaws.com/media.univariety.dev/app/themes/unifiedjourney/images/self-discovery.svg',
            messageTitle: 'Self-discovery with Univariety',
            message:
                'Students get to know themselves better and improve their skills using Univariety’s scientific tools and courses. Start now for an early advantage.',
        },
        {
            id: 20,
            imageUrl:
                'https://s3.ap-south-1.amazonaws.com/media.univariety.dev/app/themes/unifiedjourney/images/critical-career-insights.svg',
            messageTitle: 'Critical Career Insights',
            message:
                'Online Psychometric tests for career guidance are the first step in Career Planning for Students to help them discover their Aptitude, Interest and Personality.',
        },
        {
            id: 30,
            imageUrl: 'https://s3.ap-south-1.amazonaws.com/media.univariety.dev/app/themes/unifiedjourney/images/personalised-guidance.svg',
            messageTitle: 'Personalised Guidance',
            message:
                'Our one-on-one counselling sessions help students and parents explore all their options and create a customised career roadmap',
        },
        // Add more slides as needed
    ];

    const carouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        initNextBtnClickOnEnterEvent(); // @AGS-586 @dt 19th.Sep,2K23 @by RG;

        const interval = setInterval(() => {
            if (carouselRef.current) {
                const activeSlide = carouselRef.current.querySelector('.carousel-item.active') as HTMLDivElement;
                const slides = carouselRef.current.querySelectorAll('.carousel-item') as NodeListOf<HTMLDivElement>;
                const activeIndex = Array.from(slides).indexOf(activeSlide);
                const nextIndex = (activeIndex + 1) % slides.length;

                slides[activeIndex].classList.remove('active');
                slides[nextIndex].classList.add('active');

                const statusBtn = carouselRef.current.querySelectorAll('.status-btn') as NodeListOf<HTMLButtonElement>;
                statusBtn[activeIndex].classList.remove('active');
                statusBtn[nextIndex].classList.add('active');
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center w-75 text-center align-items-center">
            <div id="carouselLogin" className="carousel slide" data-bs-ride="carousel" ref={carouselRef}>
                <div className="carousel-indicators">
                    {data.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselLogin"
                            data-bs-slide-to={index}
                            className={`status-btn bg-danger ${index === 0 ? 'active' : ''}`}
                            aria-current={index === 0 ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}></button>
                    ))}
                </div>
                <div className="carousel-inner mb-5">
                    {data.map((item, index) => (
                        <div key={item.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <figure>
                                <img className="mx-auto" src={item.imageUrl} alt="" />
                            </figure>
                            <h4>{item.messageTitle}</h4>
                            <p>{item.message}</p>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselLogin" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselLogin" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default LoginCarouselControl;
