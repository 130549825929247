// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #login-form #buttonPallet {
    background-color: transparent !important;
}

#buttonPallet button {
    background-color: #5cae48;
} */
`, "",{"version":3,"sources":["webpack://./src/features/student/signup/styles.css"],"names":[],"mappings":"AAAA;;;;;;GAMG","sourcesContent":["/* #login-form #buttonPallet {\n    background-color: transparent !important;\n}\n\n#buttonPallet button {\n    background-color: #5cae48;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
