// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.triangleGraph {
    position: relative;
    clip-path: polygon(0px 0px, 100% 0px, 60% 100%, 40% 100%);
    max-width: 380px;
    width: 50%;
}

.triangleGraph ul {
    padding: 0;
    margin: 0;
}

.triangleGraph ul li span {
    color: #fff;
}

.triangleGraph ul li {
    background: #ff9f40;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.triangleGraph ul li + li {
    background: #147ad6;
}

.triangleGraph ul li + li + li {
    background: #4bc0c0;
}

.triangleGraph ul li + li + li + li {
    background: #fd6284;
}

.triangleGraph ul li + li + li + li + li {
    background: #fecb5e;
}

.triangleGraph ul li + li + li + li + li + li {
    background: #e56ac1;
}

.triangleGraph ul li:not(:last-child) {
    margin-bottom: 2px;
}

.triangleGraphLabel ul {
    padding: 0;
    margin: 0;
    text-align: right;
    line-height: 18px;
    list-style: none;
    font-weight: 500;
    font-size: 16px;
}

.triangleGraphLabel ul li {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.triangleGraphLabel ul li:not(:last-child) {
    margin-bottom: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/features/management/dashboard/FunnelChart.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yDAAyD;IACzD,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,YAAY;IAKZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".triangleGraph {\n    position: relative;\n    clip-path: polygon(0px 0px, 100% 0px, 60% 100%, 40% 100%);\n    max-width: 380px;\n    width: 50%;\n}\n\n.triangleGraph ul {\n    padding: 0;\n    margin: 0;\n}\n\n.triangleGraph ul li span {\n    color: #fff;\n}\n\n.triangleGraph ul li {\n    background: #ff9f40;\n    height: 65px;\n    display: -webkit-box;\n    display: -moz-box;\n    display: -ms-flexbox;\n    display: -webkit-flex;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.triangleGraph ul li + li {\n    background: #147ad6;\n}\n\n.triangleGraph ul li + li + li {\n    background: #4bc0c0;\n}\n\n.triangleGraph ul li + li + li + li {\n    background: #fd6284;\n}\n\n.triangleGraph ul li + li + li + li + li {\n    background: #fecb5e;\n}\n\n.triangleGraph ul li + li + li + li + li + li {\n    background: #e56ac1;\n}\n\n.triangleGraph ul li:not(:last-child) {\n    margin-bottom: 2px;\n}\n\n.triangleGraphLabel ul {\n    padding: 0;\n    margin: 0;\n    text-align: right;\n    line-height: 18px;\n    list-style: none;\n    font-weight: 500;\n    font-size: 16px;\n}\n\n.triangleGraphLabel ul li {\n    height: 65px;\n    display: flex;\n    align-items: center;\n    justify-content: end;\n}\n\n.triangleGraphLabel ul li:not(:last-child) {\n    margin-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
