import { useContext, useEffect } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import MenuBuilder from '../../../routes/MenuBuilder';
import HeaderControl from '../../alumni/_common/HeaderControl';
import GuidingAlumniPreviewFrontend from './GuidingAlumniPreviewFrontend';

const GuidingAlumniPreview = () => {
   
    const { id, pageName } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
        <div className='main flex-1 bg-white'>
            <div className="max-1140 mx-auto pt-5">
                <div className="d-flex justify-content-between align-items-center pb-3 ">
                    <h3 data-aos="fade-left" data-aos-delay="600" className="font-30 font-600 mb-0 block-title position-relative">
                        Manage Widgets For Alumni <i className="icon-right-arrow font-16 position-relative me-1"></i> <span>Guiding Alumni</span>
                    </h3>         
                </div>
            </div>

            <div className="py-5 bg-yellow-FFFAE5">
                <div className="max-1140 mx-auto">
                    <div className="row g-3">
                        <div className="col-12">
                            <div className="embed-wrap">
                                <div className="row g-3 justify-content-between align-items-center">
                                <div className="col-12">
                                    <h3 className="mb-0 font-18">Embed this post</h3>
                                </div>
                                <div className="col-12">
                                    <hr className="m-0 dotted"/>
                                </div>
                                <div className="col-auto">
                                    <div className="api-link-title">
                                    Copy and paste embed code on your site
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="link-url">
                                    <span>COPY CODE</span>
                                    <i></i>
                                </div>
                                </div>
                                <div className="col-12">
                                    <p className="text-dark-blue font-500">https://www.univariety.com/v2/alumni-college-apihttps://www.univariety.com/resources/featured-insights/detail.php? name=alumni-networking-management//https://www.univariety.com/resources/featured-insights/detail.php?name=alumni networking-management</p>
                                </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>

            <div className="max-1140 mx-auto pt-5">
                <div className="d-flex justify-content-center align-items-center pb-3 ">
                <h3 data-aos="fade-left" data-aos-delay="600" className="font-30 font-600 mb-0 block-title position-relative d-flex align-items-center">
                    <i className="icon-eye-black me-2"></i> PREVIEW</h3>         
                </div>
            </div>

            <GuidingAlumniPreviewFrontend/>

            <div className="max-1140 py-5 overflow-hidden mx-auto">
        <div className="row g-4">
    

        

          <div className="col-sm-12">
            <div className="action_btns mt-0 max-100per">
              <button type="button" className="nextbtn my-2 my-sm-0 mx-2 px-5" data-bs-toggle="modal" data-bs-target="#exampleModal">Go Back</button>
              
              <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5"><span className="px-3">Publish </span></button>
            </div>
          </div>
        </div>
      </div>
        </div>
        </>
    );
};

export default GuidingAlumniPreview;
